import React from "react"
import Layout from "../components/layout"
//import SEO from "../components/SEO"
import Hero from "../components/hero"
import Actions from "../components/actions"

export default () => (
  <Layout>
    <Hero
      title="53% of people abandon a website if it takes more than 3 seconds to load."
      text="70% of websites take over 5 seconds. Are you losing half of your potential customers because your site is slow?"
    />
    <Actions
      text="And learn about performance"
      goTo="/learn/" // Internal links
      linkTo="" // External links
      buttonText="Test your website"
    />
  </Layout>
)
